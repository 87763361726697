// @import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700");
// @import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700");

// @import 'node_modules/bootstrap/scss/variables';

/* Client App & FNO Front End Variables */

// Buttons
$aex-button-text-color: #333;
$aex-button-background-color: white;
$aex-button-border-color: #ccc;
$aex-active-button-text-color: $aex-button-background-color;
$aex-active-button-background-color: $aex-button-text-color;

// Text
$aex-text-x-muted: lighten($text-muted, 20%);
$aex-text-xx-muted: lighten($text-muted, 50%);

// Feedback
$aex-error-feedback-color: #dc3545;

:root {
	/* Colors */
	--foreground-default: #08090a;
	--foreground-secondary: #41474d;

	--background-default: #ffffff;
	--background-secondary: #eb4e1c;

	--primary-default: #083b66;

	--font-default: #000000;
	--header-colour: #000000;

	--primary-button: #022B43;
  --primary-text: #ffffff;

	/* Knox Colors */
	--app-header: #022B43;
	--app-footer: #022B43;
	--app-primary: #8C1D5D;
	--app-secondary: #E94E1F;
	--app-tertiary: #453F3D;
	--app-other: #41474d;
	--app-white: #ffffff;
	--app-black: #000000;
	--app-success: #00dc25;
	--app-warning: #ff9100;
	--app-danger: #de3131;
	--app-info: #0150d5;
	--app-light: #6969b6;

	--header: var(--app-header);
	--footer: var(--app-footer);
	--primary: var(--app-primary);
	--secondary: var(--app-secondary);
	--teritary: var(--app-teritary);
	--other: var(--app-other);
	--white: var(--app-white);
	--black: var(--app-black);
	--success: var(--app-success);
	--warning: var(--app-warning);
	--danger: var(--app-danger);
	--info: var(--app-info);
	--light: var(--app-light);

	--app-button-radius: 2rem;
	--app-button-large-width: 10rem;
	--app-button-medium-width: 8rem;
	--app-button-small-width: 6rem;
	--background-image: "";

	/* images */
	--logo: url("/assets/img/mockfno.png");
}

/* images */
$logo: var(--logo);
$background-image: var(--background-image);

/* Colors */
$foreground-default: var(--foreground-default);
$foreground-secondary: var(--foreground-secondary);
$header-colour: var(--header-colour);

$background-default: var(--background-default);
$background-secondary: var(--background-secondary);
$primary-default: var(--primary-default);
// app colour scheme
$secondary-default: var(--secondary-default);

$font-default: var(--font-default);

/* -------------------------------------------------------------------- */
/* Knox */

$app-header: var(--app-header);
$app-footer: var(--app-footer);
$app-primary: var(--app-primary);
$app-secondary: var(--app-secondary);
$app-tertiary: var(--app-tertiary);
$app-other: var(--app-other);
$app-white: var(--app-white);
$app-black: var(--app-black);
$app-success: var(--app-success);
$app-warning: var(--app-warning);
$app-danger: var(--app-danger);
$app-info: var(--app-info);
$app-light: var(--app-light);

$app-button-radius: var(--app-button-radius);
$app-button-size-small: var(--app-button-small-width);
$app-button-size-medium: var(--app-button-medium-width);
$app-button-size-large: var(--app-button-large-width);

$app-first-logo: var(--app-first-logo);
$app-second-logo: var(--app-second-logo);

// footer colouring
$contact-footer-colour: var(--footer-contact);
$contact-footer-text: var(--footer-text);

// app colour scheme
$primary-button-colour: var(--primary-button);
$primary-text: var(--primary-text);