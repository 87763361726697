@tailwind base;
@tailwind components;
@tailwind utilities;

@import "imports";
@import '~node_modules/@aex/ngx-toolbox/styles/_variables.scss';
@import "~primeicons/primeicons.css";

@font-face {
  font-family: 'proxima_nova_rgregular';
  src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
  src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),
    url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),
    url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_rgbold';
  src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot');
  src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff') format('woff'),
    url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf') format('truetype'),
    url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

.security-content {
  padding: 1.25rem 1rem 1rem 1rem;
  height: 100%;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  body {
    .security-content {
      width: 95%;
    }
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  body {
    .security-content {
      width: 95%;
    }
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  body {
    .security-content {
      width: 95%;
    }
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  body {
    .security-content {
      width: 95%;
    }
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  body {
    .security-content {
      width: 90%;
    }
  }
}

::-webkit-scrollbar {
  width: 0.5em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d3d3d3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-button-colour;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary-default;
}

.hoverIcon:hover {
  cursor: pointer;
  color: $primary-button-colour;
}

.p-datatable-wrapper {
  border: 1px solid lightgray;
}

.contact-footer {
  padding: 0.5rem;
  border-radius: 0.5rem;
	background-color: $contact-footer-colour;
}
.contact-footer,
.contact-footer .footer-email a,
.contact-footer .support-number a  {
  color: $contact-footer-text;
}

.copyright {
  margin-bottom: 1rem;
  font-size: 0.75rem;
}

.text-primary {
  color: $app-primary !important; // color gets overridden by bootstrap css
}

.text-secondary {
  color: $app-secondary;
}

.content-link:hover {
  font-weight: 600;
  cursor: pointer;
}

.header {
  padding: 1rem;
  height: 5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0.313em 0.938em 0;
}

mat-tab-group.admin-nexus {
  .mat-tab-label .mat-tab-label-content {
    font-size: 1.2em;
  }
  .mat-tab-label {
    opacity: 1 !important;
  }
  .mat-tab-labels {
    font-size: 1.2em;
  }

  .mat-tab-body-wrapper {
    height: 100%;
  }
  .mat-tab-body-content {
    background-color: #F8F9FA;
    border: 0.1em solid lightgray;
  }

  .mat-tab-label-active {
    background-color: $secondary-default;
    color: white;
  }
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $secondary-default;
  }
}

.header-transfer-table {
  background-color: #f8f9fa;
}

.security-form-field {
  width: 100%;
  .mat-form-field-suffix { // fontawesome icons don't align like mat-icons
    top: -0.25rem !important;
  }
}

.p-fluid .p-inputtext {
  width: 100%;
  border: 0.1em solid lightgrey;
  border-radius: 0.2em;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-menu-button:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: #e9ecef;
  color: #343a40;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #EFF6FF;
  color: #1D4ED8;
}

.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #BFDBFE;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-clear-button:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #BFDBFE;
}

.p-column-filter-overlay {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;
}

.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.75rem 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #1D4ED8;
  background: #EFF6FF;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #BFDBFE;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #dee2e6;
  color: #343a40;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.25rem;
  border-bottom: 1px solid #dee2e6;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.75rem 1.25rem;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.25rem;
}

.header-logo {
	background-image: $logo;
	background-repeat: no-repeat;
	margin-right: 10px;
	background-size: contain;
	background-position: center;
	width: 200px;
	height: 60px;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td > button.mat-icon-button {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
