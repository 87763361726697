@use '@angular/material' as mat;

@import "imports";

$general-border-colour: lightgrey;
$general-title-colour: rgb(153, 153, 153);

$material-primary-palette: (
	50: $secondary-default,
	100: $secondary-default,
	200: $secondary-default,
	300: $secondary-default,
	400: $secondary-default,
	500: $secondary-default,
	600: $secondary-default,
	700: $secondary-default,
	800: $secondary-default,
	900: $secondary-default,
	A100: $secondary-default,
	A200: $secondary-default,
	A400: $secondary-default,
	A700: $secondary-default,
	contrast: (
		50 : #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #000000,
		800: #000000,
		900: #000000,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000,
	)
);

$material-accent-palette: (
	50: $primary-button-colour,
	100: $primary-button-colour,
	200: $primary-button-colour,
	300: $primary-button-colour,
	400: $primary-button-colour,
	500: $primary-button-colour,
	600: $primary-button-colour,
	700: $primary-button-colour,
	800: $primary-button-colour,
	900: $primary-button-colour,
	A100: $primary-button-colour,
	A200: $primary-button-colour,
	A400: $primary-button-colour,
	A700: $primary-button-colour,
	contrast: (
		50 : #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #000000,
		800: #000000,
		900: #000000,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000,
	)
);

@include mat.core();

$primary: mat.define-palette($material-primary-palette);
$accent: mat.define-palette($material-accent-palette);

$theme: mat.define-light-theme($primary, $accent);

@include mat.all-component-themes($theme);


.title_box {
	border: $general-border-colour 1px solid;
	border-radius: 0.5rem;
	margin-top: 2rem;
}

.title_box #title {
	position: relative;
	top: -0.9em;
	margin-left: 1em;
	display: inline;
	font-size: 1.2rem;
	background-color: white;
	font-weight: 600;
}

.admin-nexus-heading {
	color: $secondary-default;
}

h2.admin-nexus-heading {
	font-size: 1.8rem;
	font-weight: 600;
}

h3.admin-nexus-heading {
	font-size: 1.2rem;
}

.admin-nexus-button.primary {
	background-color: $primary-button-colour;
	color: $primary-text;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.main-transfer-table-header {
	background-color: $app-primary;
	color: white;
	height: 2rem;
}

.transfer-button-container {
	padding-left: .4rem;
	padding-right: .4rem;
}

.transfer-prime-table .p-datatable .p-datatable-header {
	padding: 0 1rem;
}
